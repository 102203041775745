import Wizard from "Layout/Wizard/Wizard";
import Faq from "pages/Faq/Faq";
import React, { lazy } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import OTP from "pages/Migration/OTP";
import StudentDetailsLink from "pages/Migration/StudentDetailsLink";
import Voucher from "pages/Migration/Voucher";
import DeepLinkNewApp from "pages/DeepLinks/DeepLinkNewApp";

const LandingPage = lazy(() => import("pages/LandingPage/LandingPage"));
const SchoolDetails = lazy(() => import("pages/SchoolDetails/SchoolDetails"));
const SchoolDetailsWaitingList = lazy(() => import("pages/SchoolDetails/SchoolDetailsWaitingList/SchoolDetailsWaitingList"));
const RegisterForWaitingListConfirmation = lazy(() => import("pages/RegisterForWaitingListConfirmation/RegisterForWaitingListConfirmation"));
const LockerMethod = lazy(() => import("pages/LockerMethod/LockerMethod"));
const ChooseGrade = lazy(() => import("pages/ChooseGrade/ChooseGrade"));
const ChooseCompound = lazy(() => import("pages/ChooseCompound/ChooseCompound"));
const StudentDetails = lazy(() => import("pages/StudentDetails/StudentDetails"));
const ChooseSubscription = lazy(() => import("pages/ChooseSubscription/ChooseSubscription"));
const TransactionIframe = lazy(() => import("pages/TransactionIframe/TransactionIframe"));
const TransactionIframeCallback = lazy(() => import("pages/TransactionIframe/TransactionIframeCallback/TransactionIframeCallback"));
const OrderSummary = lazy(() => import("pages/OrderSummary/OrderSummary"));
const OrderConfirmation = lazy(() => import("pages/OrderConfirmation/OrderConfirmation"));
const Payment = lazy(() => import("pages/Payment/Payment"));
const Homepage = lazy(() => import("pages/Homepage/Homepage"));
const HowItWorks = lazy(() => import("pages/HowItWorks/HowItWorks"));
const TermsOfUse = lazy(() => import("pages/TermsOfUse/TermsOfUse"));
const PrivacyPolicy = lazy(() => import("pages/PrivacyPolicy/PrivacyPolicy"));

const MobileSuccess = lazy(() => import("pages/MOBILE_TRANZILA_URLS/MobileSuccess"));
const MobileFailure = lazy(() => import("pages/MOBILE_TRANZILA_URLS/MobileFailure"));

const PackageDeliveryConfirmation = lazy(() => import("pages/PackageDeliveryConfirmation/PackageDeliveryConfirmation"));

const Router = () => {
  const router = (
    <>
      <Switch>
        <Route exact path={"/voucher-number"}>
          <Voucher />
        </Route>
        <Route path={"/otp"}>
          <OTP />
        </Route>
        <Route path={"/student-details"}>
          <StudentDetailsLink />
        </Route>
        <Route path={"/mobile/mobileFailurePage"}>
          <MobileFailure />
        </Route>
        <Route path={"/how-it-works"}>
          <HowItWorks />
        </Route>
        <Route exact path={"/faq"}>
          <Faq />
        </Route>
        <Route path={"/order-confirmation"}>
          <OrderConfirmation />
        </Route>
        <Route path={"/ApproveActionPermission/:actionPermissionId/:approvalToken"}>
          <PackageDeliveryConfirmation />
        </Route>
        <Route path={"/terms-of-use"}>
          <TermsOfUse />
        </Route>
        <Route path={"/privacy-policy"}>
          <PrivacyPolicy />
        </Route>
        <Route path={"/"} exact>
          <Homepage />
        </Route>

        {/* !!!!!ONLY FOR MOBILE USES!!!!! */}
        <Route path={"/mobile/mobileSuccessPage"}>
          <MobileSuccess />
        </Route>
        <Route path={"/mobile/mobileFailurePage"}>
          <MobileFailure />
        </Route>
        <Route path={"/download-app-new"}>
          <DeepLinkNewApp />
        </Route>
        <Route path={"/onboarding/transaction-iframe-callback"}>
          <TransactionIframeCallback />
        </Route>
      </Switch>
      <Wizard>
        <Switch>
          <Route path={"/onboarding/landing-page"}>
            <LandingPage />
          </Route>
          <Route path={"/onboarding/school-details"}>
            <SchoolDetails />
          </Route>
          <Route path={"/onboarding/register-for-waiting-list"}>
            <SchoolDetailsWaitingList />
          </Route>
          <Route path={"/onboarding/register-for-waiting-list-confirmation"}>
            <RegisterForWaitingListConfirmation />
          </Route>
          <Route path={"/onboarding/locker-method"}>
            <LockerMethod />
          </Route>
          <Route path={"/onboarding/choose-grade"}>
            <ChooseGrade />
          </Route>
          <Route path={"/onboarding/choose-compound"}>
            <ChooseCompound />
          </Route>
          <Route path={"/onboarding/student-details"}>
            <StudentDetails />
          </Route>
          <Route path={"/onboarding/choose-subscription"}>
            <ChooseSubscription />
          </Route>
          <Route path={"/onboarding/transaction-iframe"}>
            <TransactionIframe />
          </Route>
          <Route path={"/onboarding/order-summary"}>
            <OrderSummary />
          </Route>
          <Route path={"/onboarding/payment"}>
            <Payment />
          </Route>
          <Route path={"/*"}>
            <Homepage />
          </Route>
        </Switch>
      </Wizard>
    </>
  );

  return router;
};

export default Router;
