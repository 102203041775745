import CONSTANTS from "utils/constants";

const OnBoardingSelectors = {
    orderMethod: state => state.onBoardingProcessReducer.orderMethod,
    isImmediateOrder: state => {
        const orderMethod = state.onBoardingProcessReducer.orderMethod
        if (orderMethod === null || orderMethod === undefined) {
            return null;
        }
        return orderMethod === CONSTANTS.ORDER_METHODS.IMMEDIATE_ORDER;
    },
    cityId:  state => state.onBoardingProcessReducer.cityId,
    cityNameHe:  state => state.onBoardingProcessReducer.cityNameHe,
    siteId:  state => state.onBoardingProcessReducer.siteId,
    siteNameHe:  state => state.onBoardingProcessReducer.siteNameHe,
    compoundId: state => state.onBoardingProcessReducer.compound?.id,
    compoundNameHE: state => state.onBoardingProcessReducer.compound?.nameHE,
    compoundDescription: state => state.onBoardingProcessReducer.compound?.description,

    closetId: state => state.onBoardingProcessReducer.closet?.id,
    closetNumber: state => state.onBoardingProcessReducer.closet?.closetNumber,
    cellId: state => state.onBoardingProcessReducer.cell?.id,
    cellSize: state => state.onBoardingProcessReducer.cell?.cellSize,
    cellPosition: state => state.onBoardingProcessReducer.cell?.position,
    isCellUnavailable: state => state.onBoardingProcessReducer.cell?.isCellUnavailable,

    pricingPeriodId: state => state.onBoardingProcessReducer.pricingPlan?.pricingPeriodId,
    pricingPlanId: state => state.onBoardingProcessReducer.pricingPlan?.pricingPlanId,
    initialPrice: state => state.onBoardingProcessReducer.pricingPlan?.initialPrice,
    unitPrice: state => state.onBoardingProcessReducer.pricingPlan?.unitPrice,
    pricingPeriodName: state => state.onBoardingProcessReducer.pricingPlan?.pricingPeriodName,
    
    gradeId: state => state.onBoardingProcessReducer.gradeId,

    firstName: state => state.onBoardingProcessReducer.userDetails.firstName,
    lastName: state => state.onBoardingProcessReducer.userDetails.lastName,
    fullName: state => `${state.onBoardingProcessReducer.userDetails.firstName} ${state.onBoardingProcessReducer.userDetails.lastName}`,
    phoneNumber: state => state.onBoardingProcessReducer.userDetails.phoneNumber,
    email: state => state.onBoardingProcessReducer.userDetails.email,
    joinMailingList: state => state.onBoardingProcessReducer.userDetails.joinMailingList,
    agreementPolicy: state => state.onBoardingProcessReducer.userDetails.agreementPolicy,

    tranzilaResponse: state => state.onBoardingProcessReducer.tranzilaResponse,
    transactionId: state => state.onBoardingProcessReducer.tranzilaResponse?.transactionId,
    cardToken: state => state.onBoardingProcessReducer.tranzilaResponse?.cardToken,
    expiryMonth: state => state.onBoardingProcessReducer.tranzilaResponse?.expiryMonth,
    expiryYear: state => state.onBoardingProcessReducer.tranzilaResponse?.expiryYear,
    last4Digits: state => state.onBoardingProcessReducer.tranzilaResponse?.last4Digits,
    cardType: state => state.onBoardingProcessReducer.tranzilaResponse?.cardType,
    cardIssuer: state => state.onBoardingProcessReducer.tranzilaResponse?.cardIssuer,

    orderId: state => state.onBoardingProcessReducer.order?.orderId,
    startDate: state => state.onBoardingProcessReducer.order?.startDate,
    endDate: state => state.onBoardingProcessReducer.order?.endDate,

    loading:  state => state.onBoardingProcessReducer.loading
}

export default OnBoardingSelectors;