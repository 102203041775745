import React, { useEffect, useState } from "react";

import useTranslations from "hooks/useTranslations";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MUIAutocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import FieldError from "components/FieldError/FieldError";


import "./AutoComplete.scss";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


const AutoComplete = ({
  items = [],
  onChange,
  onBlur,
  label,
  className = "",
  id,
  error,
  value,
  multiple = true,
  disabled = false,
  shrink = true,
  placeholder,
  additionalLabelData = "",
  additionalTitle = "",
  handleAdditionalTitleClick,
  openWhenClick = true,
  endAdornment = null
}) => {
  const DEFAULT_INPUT_WIDTH = "100%";

  const [open, setOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState(multiple ? [] : null);
  const [inputWidth, setInputWidth] = useState(DEFAULT_INPUT_WIDTH);
  const [optionLabel, setOptionLabel] = useState(null);
  const [visibleAdditionalLabel, setVisibleAdditionalLabel] = useState(true);

  const { rcTranslate } = useTranslations();


  useEffect(() => {
    setSelectedItems(value);
  }, [value]);

  useEffect(() => {
    if (optionLabel?.length) {
      setInputWidth(optionLabel.length * 6.5);
    } else {
      setInputWidth(DEFAULT_INPUT_WIDTH);
    }
  }, [optionLabel]);

  const handleFocus = () => {
    setVisibleAdditionalLabel(false);
  };

  const handleChangeTextField = (event) => {
    if(event.target.value?.length>0){
      setOpen(true);
    }else{
      setOpen(false);
    }
  };

  const handleBlur = () => {
    setVisibleAdditionalLabel(true);
    setOpen(false);

    onBlur && onBlur();
  };

  const handleChange = (_event, value) => {
    if (value === null) {
      return onChange && onChange(multiple ? [] : null);
    }

    let items = [];

    if (Array.isArray(value)) {
      items = value?.map((item) => item.id);
    } else {
      items = value.id;
    }

    onChange && onChange(items);
  };

  const getFieldValue = () => {
    if (items?.length && selectedItems) {
      if (Array.isArray(selectedItems)) {
        const foundValue = items.filter((item) =>
          selectedItems.includes(item.id)
        );

        return foundValue;
      } else {
        const foundValue = items.find((item) => item.id === selectedItems);

        if (foundValue) {
          return foundValue;
        }
      }
    }

    if (multiple) {
      return [];
    }

    return null;
  };
  
  return (
    <div className="autoCompleteWrapper">
      <MUIAutocomplete
        multiple={multiple}
        className={`autoComplete ${className}`}
        open={open}
        id={id}
        options={items}
        value={getFieldValue()}
        disableCloseOnSelect={multiple}
        disabled={disabled}
        blurOnSelect
        onChange={handleChange}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            style={{ direction: "rtl" }}
            className="scroller"
            key={option.id + option.label}
          >
            {multiple && (
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selectedItems.includes(option.id)}
              />
            )}

            {option.label}
            {option.additionalLabel !== null && option.additionalLabel}
          </li>
        )}
        style={{ width: "100%" }}
        renderInput={(params) => {
          return (
            <div className="autoComplete__textField-wrapper" onClick={() => !disabled ? setOpen(!open) : '' }>
              <InputLabel className="autoComplete__inputLabel-hyper-link" 
                  onClick={handleAdditionalTitleClick}>{additionalTitle}</InputLabel>
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: endAdornment ? endAdornment : params.InputProps.endAdornment
                }}
                className={`autoComplete__textField ${
                  error ? "autoComplete__textField-error" : ""
                } ${value ? "autoComplete__textField-filled" : ""}`}
                sx={{
                  ".MuiOutlinedInput-input.MuiInputBase-input": {
                    width: inputWidth
                  }
                }}
                label={label}
                variant="outlined"
                error={!!error}
                placeholder={placeholder}
                InputLabelProps={{ shrink }}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onChange={handleChangeTextField}
              />

              {visibleAdditionalLabel && additionalLabelData && (
                <div
                  className="autoComplete__textField-additionalLabel"
                  style={{
                    right: additionalLabelData.length + inputWidth + 30
                  }}
                >
                  {additionalLabelData}
                </div>
              )}
            </div>
          );
        }}
      />

      <FieldError error={rcTranslate(error)} />
    </div>
  );
};

export default AutoComplete;
