import React, {useState, useEffect} from "react";
import MUIDialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Button from "../Button/Button";
import generalError from "assets/images/generalError.png";
import errorsSelectors from '../../store/errors/errorsSelectors';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorActions } from '../../store/errors/errorsActions';
import useTranslations from "hooks/useTranslations";
import { ReactComponent as CreditCard } from "assets/images/creditCard.svg";
import { ReactComponent as ClosedSign } from "assets/images/closed-sign.svg";
import { useHistory } from "react-router-dom";
import { ReactComponent as ClosetImage } from "assets/images/Closet.svg";
import ErrorCodes from "utils/constants/errorCodes";

import "./UltimateErrorsModal.scss";
import { Link } from "@mui/material";

export default function UltimateErrorsModal() {
    const dispatch = useDispatch();
    const { rcTranslate } = useTranslations();
    const history = useHistory();

    const shouldShowErrorModal = useSelector(errorsSelectors.isErrorsExist);
    const nextError = useSelector(errorsSelectors.getNextError);

    const [currentError, setCurrentError] = useState();

    const [headerImage, setHeaderImage] = useState();
    const [headerTitle, setHeaderTitle] = useState();
    const [headerSubTitle, setHeaderSubTitle] = useState();
    const [buttonText, setButtonText] = useState();
    const [clickableText, setClickableText] = useState();

    useEffect(() => {
        if(nextError?.code) setCurrentError(nextError);
      }, [nextError?.code])
    
      useEffect(() => {
        setHeaderImageBasedOnErrorCode(currentError?.code);
        setHeaderTitleTextBasedOnErrorCode(currentError?.code, currentError?.message);
        setHeaderSubTitleTextBasedOnErrorCode(currentError?.code, currentError?.message);
        setMainButtonTextBasedOnErrorCode(currentError?.code, currentError?.message);
        setClickableTextBasedOnErrorCode(currentError?.code, currentError?.message);
      }, [currentError?.code]);

      const setHeaderImageBasedOnErrorCode = (errorCode, errorMessage) =>  {
        switch (errorCode?.toString()) {
          case ErrorCodes.TRANZILA_GENERIC: 
          case ErrorCodes.TRANZILA_NO_CHARGE_WAS_MADE:
            setHeaderImage(<CreditCard/>);
            break;
          case ErrorCodes.CellUnavailable:
            setHeaderImage(<ClosetImage/>);
            break;
          case ErrorCodes.NoAvailableCellsFound:
            setHeaderImage(<ClosetImage/>);
              break;
          case ErrorCodes.NoAvailableCellsFoundRegisterForWaitingList:
            setHeaderImage(<ClosetImage/>);
              break;
          case ErrorCodes.SiteIsNotOpenForOrders:
            setHeaderImage(<ClosedSign/>);
              break;
          default:
            setHeaderImage(<img src={generalError} />);
            break;
        }
      }
    
      const setHeaderTitleTextBasedOnErrorCode = (errorCode, errorMessage) => {
        switch (errorCode?.toString()) {
          case ErrorCodes.TRANZILA_GENERIC:
          case ErrorCodes.TRANZILA_NO_CHARGE_WAS_MADE:
            setHeaderTitle(rcTranslate("ultimateErrorModal.tranzila.title"));
            break;
          case ErrorCodes.CellUnavailable:
            setHeaderTitle(rcTranslate("ultimateErrorModal.unAvailableCell.title"));
            break;
          case ErrorCodes.NoAvailableCellsFound:
            setHeaderTitle(rcTranslate("ultimateErrorModal.noAvailableCellsFound.title"));
              break;
          case ErrorCodes.NoAvailableCellsFoundRegisterForWaitingList:
            setHeaderTitle(rcTranslate("ultimateErrorModal.noAvailableCellsFoundRegisterForWaitingList.title"));
              break;
          case ErrorCodes.SiteIsNotOpenForOrders:
            setHeaderTitle(rcTranslate("ultimateErrorModal.siteIsNotOpenForOrders.title"));
              break;
          default:
            setHeaderTitle(errorMessage ? errorMessage : rcTranslate("ultimateErrorModal.title"));
            break;
        }
      }

      const setHeaderSubTitleTextBasedOnErrorCode = (errorCode) => {
        switch (errorCode?.toString()) {
          case ErrorCodes.TRANZILA_GENERIC:
            setHeaderSubTitle(rcTranslate("ultimateErrorModal.tranzila.subTitleGeneric"));
            break;
          case ErrorCodes.TRANZILA_NO_CHARGE_WAS_MADE:
            setHeaderSubTitle(rcTranslate("ultimateErrorModal.tranzila.subTitleNoChargeWasMade"));
            break;
          case ErrorCodes.CellUnavailable:
            setHeaderSubTitle(rcTranslate("ultimateErrorModal.unAvailableCell.subTitle"));
              break;
          case ErrorCodes.NoAvailableCellsFound:
            setHeaderSubTitle(rcTranslate("ultimateErrorModal.noAvailableCellsFound.subTitle"));
              break;
          case ErrorCodes.NoAvailableCellsFoundRegisterForWaitingList:
            const getText = (key) => rcTranslate(`ultimateErrorModal.noAvailableCellsFoundRegisterForWaitingList.${key}`);

            const NoAvailableCellsFoundRegisterForWaitingListHeaderSubTitle = (
              <div>
                <div>
                  {getText("subTitle")}
                  <b className="boldWrapper">{`${getText("inCompound")} ${currentError?.compound?.nameHE} - ${currentError?.compound?.description}`}</b>
                  {getText("areOccupied")}
                </div>
                <div>
                {getText("subTitle2")}
                </div>
                <br/>
                <div>
                  <b className="boldWrapper">{getText("isThereNoAvailableCells")}</b>
                  {getText("youCanRegisterForWaitingList")}
                </div>
              </div>
            )
            setHeaderSubTitle(NoAvailableCellsFoundRegisterForWaitingListHeaderSubTitle);
              break;
          case ErrorCodes.SiteIsNotOpenForOrders:
            const siteIsNotOpenForOrdersHeaderSubTitle = (
              <div>
                <div>
                  {rcTranslate("ultimateErrorModal.siteIsNotOpenForOrders.subTitle")}
                  <b className="boldWrapper">{`${currentError?.site.label}.`}</b>

                </div>
                <div>
                {rcTranslate("ultimateErrorModal.siteIsNotOpenForOrders.subTitle2")}
                </div>
              </div>
            )
            setHeaderSubTitle(siteIsNotOpenForOrdersHeaderSubTitle);
              break;
          default:
            setHeaderSubTitle("");
        }
      }

      const setMainButtonTextBasedOnErrorCode = (errorCode) => {  
        switch (errorCode?.toString()) {
          case ErrorCodes.TRANZILA_GENERIC: 
          case ErrorCodes.TRANZILA_NO_CHARGE_WAS_MADE:
            setButtonText(rcTranslate("ultimateErrorModal.tranzila.buttonText"));
            break;
          case ErrorCodes.CellUnavailable:
              setButtonText(rcTranslate("ultimateErrorModal.unAvailableCell.buttonText"));
              break;
          case ErrorCodes.NoAvailableCellsFound:
            setButtonText(rcTranslate("ultimateErrorModal.noAvailableCellsFound.buttonText"));
              break;
          case ErrorCodes.NoAvailableCellsFoundRegisterForWaitingList:
            setButtonText(rcTranslate("ultimateErrorModal.noAvailableCellsFoundRegisterForWaitingList.buttonText"));
              break;
          case ErrorCodes.SiteIsNotOpenForOrders:
            setButtonText(rcTranslate("ultimateErrorModal.siteIsNotOpenForOrders.buttonText"));
              break;
          default:
            setButtonText(rcTranslate("ultimateErrorModal.confirm"));
          break;
        }
      }

      const setClickableTextBasedOnErrorCode = (errorCode) => {
        switch (errorCode?.toString()) {
          case ErrorCodes.NoAvailableCellsFoundRegisterForWaitingList:
            setClickableText(rcTranslate("ultimateErrorModal.noAvailableCellsFoundRegisterForWaitingList.clibkableText"));
            break;
          case ErrorCodes.SiteIsNotOpenForOrders:
            setClickableText(rcTranslate("ultimateErrorModal.siteIsNotOpenForOrders.clibkableText"));
            break;
          default:
            setClickableText(null);
        }
      }
    

      const createCallbackFunctionForCloseButtonPress = (errorCode) => {
        switch (errorCode?.toString()) {
          case ErrorCodes.TRANZILA_GENERIC: 
          case ErrorCodes.TRANZILA_NO_CHARGE_WAS_MADE:
            return history.push("/onboarding/transaction-iframe");
          case ErrorCodes.CellUnavailable:
            return history.push("/onboarding/choose-compound");
          case ErrorCodes.RecaptchaNotValid:
            return history.push("/onboarding/transaction-iframe");
          case ErrorCodes.RecaptchaNotValid:
            return history.push("/onboarding/transaction-iframe");
          default:
            return () => {}
        }
      }

      const createCallbackFunctionForMainButtonPress = (errorCode) => {
        switch (errorCode?.toString()) {
          case ErrorCodes.NoAvailableCellsFoundRegisterForWaitingList:
            return history.push("/onboarding/register-for-waiting-list");
          case ErrorCodes.SiteIsNotOpenForOrders:
            return history.push("/onboarding/register-for-waiting-list");
          default:
            return createCallbackFunctionForCloseButtonPress(errorCode);
        }
      }

      const createCallbackFunctionForClickableTextPress = (errorCode) => {
        switch (errorCode?.toString()) {
          default:
            return () => {}
        }
      }

      const closePopup = (callbackFunction) => {
        callbackFunction && callbackFunction();
        dispatch(ErrorActions.popError());
      }

  return (
    <MUIDialog
      onClose={() => closePopup(createCallbackFunctionForCloseButtonPress(currentError?.code))}
      aria-labelledby="customized-dialog-title"
      open={shouldShowErrorModal}
      className="dialogComponent"
      sx={{
        "& .MuiDialogTitle-root": {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "16px 24px 0px",
          flexDirection: "row-reverse",
          direction: "rtl"
        },
        "& .MuiDialogContent-root": {
          direction: "rtl",
          border: "none !important",
        },
        "& .MuiDialogActions-root": {
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "row"
        },
      }}
    >
      <DialogTitle sx={{ m: 0, p: 1 }}>
        <IconButton
          aria-label="close"
          onClick={() => closePopup(createCallbackFunctionForCloseButtonPress(currentError?.code))}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        <div className="titleImageWrapper">
          {headerImage}
        </div>
        <div className="titleWrapper">
          <h3>
            {headerTitle}
          </h3>
        </div>
        <div className="subTitleWrapper">
          <p>
            {headerSubTitle}
          </p>
        </div>
      </DialogContent>

      <DialogActions className="dialogActions">

        <Button
          title={buttonText}
          onClick={() => closePopup(createCallbackFunctionForMainButtonPress(currentError?.code))}
        />

        {clickableText && (
          <div>
            <Link  className="cliackableText" onClick={() => closePopup(createCallbackFunctionForClickableTextPress(currentError?.code))}>
              {clickableText}
            </Link>
          </div>)}

      </DialogActions>
    </MUIDialog>
  );
}
