import { createAsyncThunk } from "@reduxjs/toolkit";
import store from '../store';
import { ApiService } from "services";
import { convertStringToObject } from "utils/helpers";
import { setCompound, resetCompound, setCloset, resetCloset, setCell, resetCell, setPricingPlan } from '../../store/onBoardingProcess/onBoardingProcessReducer';
import { setTranzilaResponse }  from '../../store/onBoardingProcess/onBoardingProcessReducer';
import { useRecaptcha } from 'hooks/useRecaptcha';


export const noValidateRegistration = createAsyncThunk('onBoardingProcess/noValidateRegistration', async (history) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { getRecaptchaKey, getRecaptchaSiteKey } = useRecaptcha();
      const phoneNumber = store.getState().onBoardingProcessReducer.userDetails?.phoneNumber;
      const joinMailingList = store.getState().onBoardingProcessReducer.userDetails?.joinMailingList;

      getRecaptchaKey(() => {
        window.grecaptcha.ready(async () => {
          try {
            const responseToken = await window.grecaptcha.execute(getRecaptchaSiteKey(), { action: 'submit' });
            const request = {
              phoneNumber: phoneNumber,
              mailSubscription: joinMailingList,
              recaptchaKey: responseToken
            };
            const response = await ApiService.post('Account/NoValidateRegistration', request);
            if (response.data?.isSucceeded) {
            resolve();
            return history.push("/onboarding/transaction-iframe");
            } else {
              resolve();
            }
          } catch (error) {
            console.error(error);
            reject(new Error('Error during API request'));
          }
        });
      });
    } catch (error) {
      console.error(error);
      reject(new Error('Error get recaptcha'));
    }
  });
});

export const packageDeliveryConfirmation = createAsyncThunk('onBoardingProcess/packageDeliveryConfirmation', async (request) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { getRecaptchaKey, getRecaptchaSiteKey } = useRecaptcha();
        getRecaptchaKey(() => {
          window.grecaptcha.ready(async () => {
            try {      
              const responseToken = await window.grecaptcha.execute(getRecaptchaSiteKey(), { action: 'submit' });
              request.recaptchaKey= responseToken;
              const response = await ApiService.put('ActionPermission/ApproveActionPermission',request);
              resolve(response);
            } catch (error) {
              console.error(error);
              reject(new Error('Error during API request'));
            }
          });
        });
    } catch (error) {
      console.error(error);
      reject(new Error('Error get recaptcha'));
    }
  });
});

export const getAnonymousCardIframe = createAsyncThunk('onBoardingProcess/getAnonymousCardIframe', async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { getRecaptchaKey, getRecaptchaSiteKey } = useRecaptcha();
      const phoneNumber = store.getState().onBoardingProcessReducer.userDetails?.phoneNumber;
      const initialPrice = store.getState().onBoardingProcessReducer.pricingPlan?.initialPrice;
      const unitPrice = store.getState().onBoardingProcessReducer.pricingPlan?.unitPrice;
      const orderMethod = store.getState().onBoardingProcessReducer.orderMethod;
      const totalPrice = initialPrice + unitPrice;

      getRecaptchaKey(() => {
        window.grecaptcha.ready(async () => {
          try {
            const responseToken = await window.grecaptcha.execute(getRecaptchaSiteKey(), { action: 'submit' });
            const url = `CustomerCard/GetAnonymousCardIFrame?phoneNumber=${phoneNumber}&totalPrice=${totalPrice}&orderMethod=${orderMethod}&recaptchaKey=${responseToken}`;

            const response = await ApiService.get(url);
            resolve(response);
          } catch (error) {
            console.error(error);
            reject(new Error('Error during API request'));
          }
        });
      });
    } catch (error) {
      console.error(error);
      reject(new Error('Error get recaptcha'));
    }
  });
});

export const webOnboardingOrder = createAsyncThunk('onBoardingProcess/webOnboardingOrder', async () => {
  const phoneNumber = store.getState().onBoardingProcessReducer.userDetails?.phoneNumber;
  const firstName = store.getState().onBoardingProcessReducer.userDetails?.firstName;
  const lastName = store.getState().onBoardingProcessReducer.userDetails?.lastName;
  const email = store.getState().onBoardingProcessReducer.userDetails?.email;

  const cellId = store.getState().onBoardingProcessReducer.cell?.id;
  const pricingPlanId = store.getState().onBoardingProcessReducer.pricingPlan?.pricingPlanId;
  const transactionId = store.getState().onBoardingProcessReducer.tranzilaResponse?.transactionId;
  const cardToken = store.getState().onBoardingProcessReducer.tranzilaResponse?.cardToken;
  const expiryMonth = store.getState().onBoardingProcessReducer.tranzilaResponse?.expiryMonth;
  const expiryYear = store.getState().onBoardingProcessReducer.tranzilaResponse?.expiryYear;

  return new Promise(async (resolve, reject) => {
    try {
      const { getRecaptchaKey, getRecaptchaSiteKey } = useRecaptcha();

      getRecaptchaKey(() => {
        window.grecaptcha.ready(async () => {
          try {
            const responseToken = await window.grecaptcha.execute(getRecaptchaSiteKey(), { action: 'submit' });
            const request = {
              phoneNumber: phoneNumber,
              firstName: firstName,
              lastName: lastName,
              email: email,
              cellId: cellId,
              pricingPlanId: pricingPlanId,
              transactionId: transactionId,
              cardToken: cardToken,
              expiryMonth: expiryMonth,
              expiryYear: expiryYear,
              recaptchaKey: responseToken
          }

            const response = await ApiService.post('Order/WebOnboardingOrder', request);
            resolve(response);
          } catch (error) {
            console.error(error);
            reject(new Error('Error during API request - WebOnboardingOrder'));
          }
        });
      });
    } catch (error) {
      console.error(error);
      reject(new Error('Error get recaptcha'));
    }
  });
});

export const webOnbonboardingOptionalPreOrder = createAsyncThunk('onBoardingProcess/webOnbonboardingOptionalPreOrder', async () => {
  const phoneNumber = store.getState().onBoardingProcessReducer.userDetails?.phoneNumber;
  const firstName = store.getState().onBoardingProcessReducer.userDetails?.firstName;
  const lastName = store.getState().onBoardingProcessReducer.userDetails?.lastName;
  const email = store.getState().onBoardingProcessReducer.userDetails?.email;

  const siteId = store.getState().onBoardingProcessReducer.siteId;

  const pricingPlanId = store.getState().onBoardingProcessReducer.pricingPlan?.pricingPlanId;
  const transactionId = store.getState().onBoardingProcessReducer.tranzilaResponse?.transactionId;
  const cardToken = store.getState().onBoardingProcessReducer.tranzilaResponse?.cardToken;
  const expiryMonth = store.getState().onBoardingProcessReducer.tranzilaResponse?.expiryMonth;
  const expiryYear = store.getState().onBoardingProcessReducer.tranzilaResponse?.expiryYear;

  return new Promise(async (resolve, reject) => {
    try {
      const { getRecaptchaKey, getRecaptchaSiteKey } = useRecaptcha();

      getRecaptchaKey(() => {
        window.grecaptcha.ready(async () => {
          try {
            const responseToken = await window.grecaptcha.execute(getRecaptchaSiteKey(), { action: 'submit' });
            const request = {
              phoneNumber: phoneNumber,
              firstName: firstName,
              lastName: lastName,
              email: email,
              siteId: siteId,
              pricingPlanId: pricingPlanId,
              transactionId: transactionId,
              cardToken: cardToken,
              expiryMonth: expiryMonth,
              expiryYear: expiryYear,
              recaptchaKey: responseToken
          }
            const response = await ApiService.post('Order/webOnbonboardingOptionalPreOrder', request);
            resolve(response);
          } catch (error) {
            console.error(error);
            reject(new Error('Error during API request - webOnbonboardingOptionalPreOrder'));
          }
        });
      });
    } catch (error) {
      console.error(error);
      reject(new Error('Error get recaptcha'));
    }
  });
});

export const webOnboardingRegisterForWaitingList = createAsyncThunk('onBoardingProcess/webOnboardingRegisterForWaitingList', async () => {
  const phoneNumber = store.getState().onBoardingProcessReducer.userDetails?.phoneNumber;
  const firstName = store.getState().onBoardingProcessReducer.userDetails?.firstName;
  const lastName = store.getState().onBoardingProcessReducer.userDetails?.lastName;
  const email = store.getState().onBoardingProcessReducer.userDetails?.email;
  const siteId = store.getState().onBoardingProcessReducer.siteId;
  const compoundId = store.getState().onBoardingProcessReducer.compound?.id;

  return new Promise(async (resolve, reject) => {
    try {
      const { getRecaptchaKey, getRecaptchaSiteKey } = useRecaptcha();

      getRecaptchaKey(() => {
        window.grecaptcha.ready(async () => {
          try {
            const responseToken = await window.grecaptcha.execute(getRecaptchaSiteKey(), { action: 'submit' });
            const request = {
              phoneNumber: phoneNumber,
              firstName: firstName,
              lastName: lastName,
              email: email,
              siteId: siteId,
              compoundId: compoundId,
              recaptchaKey: responseToken
          }

            const response = await ApiService.post('WaitingList/RegisterUserForWaitingList', request);
            resolve(response);
          } catch (error) {
            console.error(error);
            reject(new Error('Error during API request - webOnboardingRegisterForWaitingList'));
          }
        });
      });
    } catch (error) {
      console.error(error);
      reject(new Error('Error get recaptcha'));
    }
  });
});

export const DispatchCompoundValues = (compoundId, dispatch) => {
    if(!compoundId){
        return dispatch(resetCompound());
    }
    const siteHierarchy = store.getState().siteReducer.siteHierarchy;
    
    if(!siteHierarchy) return;
    
    const compounds = siteHierarchy.compounds;
    const compoundIndex = compounds.findIndex(c => c.id === compoundId);
    if(compoundIndex < 0) return;
    
    const compound = {
        id: compounds[compoundIndex].id,
        nameHE: compounds[compoundIndex].nameHE,
        nameEN: compounds[compoundIndex].nameEN,
        description: compounds[compoundIndex].description
    };

    dispatch(setCompound(compound));
}

export const DispatchClosetValues = (closetId, dispatch) => {
    if(!closetId){
        return dispatch(resetCloset());
    }
    const siteHierarchy = store.getState().siteReducer.siteHierarchy;
    
    if(!siteHierarchy) return;
    
    const compounds = siteHierarchy.compounds;

    for (const compound of compounds) {
        const closet = compound.closets.find((closet) => closet.id === closetId);
        if (closet) {
          const targetCloset = {
            id: closet.id,
            closetNumber: closet.closetNumber
          };
          return dispatch(setCloset(targetCloset));
        }
      }
}

export const DispatchCellValues = (cellId, dispatch) => {
    if(!cellId){
        return dispatch(resetCell());
    }
    const siteHierarchy = store.getState().siteReducer.siteHierarchy;
    
    if(!siteHierarchy) return;
    
    const compounds = siteHierarchy.compounds;

    for (const compound of compounds) {
        for (const closet of compound.closets) {
          const cell = closet.cells.find((cell) => cell.id === cellId);
          if (cell) {
            const targetCell = {
                id: cell.id,
                cellNumber: cell.cellNumber,
                cellSize: cell.cellSize,
                position: cell.position,
                isCellUnavailable: false
              };
              return dispatch(setCell(targetCell));
          }
        }
      }
}

export const DispatchPricingPlanAndPeriodValues = (pricingPlan, cellSize, dispatch) => {
  const pricingPeriod = pricingPlan.pricings.find(p => p.cellSize === cellSize);

  const targetPricingPlanAndPeriod = {
    pricingPlanId: pricingPlan.id,
    marketingName: pricingPlan.marketingName,
    subscriptionModel: pricingPlan.subscriptionModel,
    cellSize: cellSize,
    pricingPeriodId: pricingPlan.pricingPeriod,
    initialPrice: pricingPeriod.initialPrice,
    unitPrice: pricingPeriod.unitPrice,
    pricingPeriodName: pricingPeriod.nameHE
  };

  dispatch(setPricingPlan(targetPricingPlanAndPeriod));
}

export const DispatchTranzilaResponseValues = (tranzilaResponseStr, dispatch) => {
    const fullTranzilaResponseObject = convertStringToObject(tranzilaResponseStr);

    const targetTranzilaResponse = {
      transactionId: +fullTranzilaResponseObject.index,
      cardToken: fullTranzilaResponseObject.TranzilaTK,
      expiryMonth: +fullTranzilaResponseObject.expmonth, 
      expiryYear: +fullTranzilaResponseObject.expyear,
      last4Digits: fullTranzilaResponseObject.ccno ? fullTranzilaResponseObject.ccno : fullTranzilaResponseObject.TranzilaTK.slice(-4),
      cardType: +fullTranzilaResponseObject.cardtype,
      cardIssuer: +fullTranzilaResponseObject.cardissuer,
    };

    return dispatch(setTranzilaResponse(targetTranzilaResponse));
}
